import React, { useState } from 'react';
import '../../styles/global.css';


const options2 = [
  {
    value: '25,000',
    label: '25,000',
  },
  {
    value: '50,000',
    label: '50,000',
  },
  {
    value: '200,000',
    label: '200,000',
  },
  {
    value: '300,000',
    label: '300,000',
  },
];

export default function Dropdown2() {
  const [open, setOpen] = useState(false);

  const [selectedOptions2, setSelectedOptions2] = useState(options2[0]);

  const renderedOptions = (options2.map((option) => {
    if (option.value === selectedOptions2.value) {
      return null;
    }
    return (
      <div
        key={option.value}
        className='item'
        onClick={() => setSelectedOptions2(option)}>
        {option.label}
      </div>
    );
  }));
  
  return (
    <div>
      <div className='dropdown'>
        <div className='field'>
          <label onClick={() => setOpen(!open)} className='label'>Choose Your Budget</label>
          <div className={`visible ${open ? 'active' : ''}`}>
            {open && (
              <>
                <div className='text'>{selectedOptions2.label}</div>
                <div className='visible transition'>{renderedOptions}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
